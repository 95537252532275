import { useCallback, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import OAuth from '../../lib/OAuth';
import qs from 'qs';
import {
  SVGClose, SVGArrow, SVGPadlockLocked, SVGRetest,
  SVGExport, SVGRefreshLoading, SVGPencil,
  SVGPlus, SVGTick, SVGImport, SVGSend, SVGExternalLink,
  SVGRestore, SVGInfo, SVGEyeVisible, SVGPlusCircleSolid, SVGPrint,
  SVGHome, SVGDuplicate, SVGFilters, SVGClear, SVGArrowDouble, SVGArrowSolid, SVGDeleteCircleSolid
} from '_Icons';

import classNames from 'classnames';
import './Button.scss';
import { useLoggingReducer, useUpdatingElementRef, useAPICallback, useAnimatingState } from '_Hooks';
import { DropDownModal } from '_Elements/modal';
import Option from './Option';
import { MIME_TYPE_EXTENSIONS } from '../../lib/Enum';
import Log from '../../lib/Log';
import { ToolTipWrapper, TOOLTIP_SIZE } from '_Elements/tool-tip';
import { isString, eventDidBlur } from '../../lib/Utils';

export const BUTTON_PRIORITY = {
  primary: 'primary',
  secondary: 'secondary',
  tertiary: 'tertiary',
  quaternary: 'quaternary'
};
export const BUTTON_THEME = {
  circle: 'circle',
  text: 'text',
  wysiwyg: 'wysiwyg'
};
export const BUTTON_ICON = {
  home: 'home',
  close: 'close',
  retest: 'retest',
  scroll_top: 'scroll-top',
  export: 'export',
  import: 'import',
  padlock: 'padlock',
  pencil: 'pencil',
  plus: 'plus',
  tick: 'tick',
  delete_circle: 'delete-circle',
  plus_circle: 'plus-circle',
  scroll_bottom: 'scroll-bottom',
  send: 'send',
  restore: 'restore',
  info: 'info',
  eye: 'eye',
  print: 'print',
  duplicate: 'duplicate',
  filters: 'filters',
  clear: 'clear',
  external_link: 'external_link'
};
export const BUTTON_FLOW = {
  FORWARD: 'forward',
  FORWARD_2: 'forward_2',
  FORWARD_3: 'forward_3',
  BACKWARD: 'backward',
  BACKWARD_2: 'backward_2',
  BACKWARD_3: 'backward_3'
};

const b64toArrayBuffer = b64Data => {
  let byteString = decodeURIComponent(escape(atob(b64Data)));
  // write the bytes of the string to an ArrayBuffer
  let ab = new ArrayBuffer(byteString.length);
  let ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return ab;
};

const ButtonImage = ({ icon }) => {
  if (!isString(icon)) return icon;
  switch (icon) {
    case BUTTON_ICON.filters: return <SVGFilters size={14} />;
    case BUTTON_ICON.duplicate: return <SVGDuplicate size={14} />;
    case BUTTON_ICON.home: return <SVGHome size={14} />;
    case BUTTON_ICON.clear: return <SVGClear size={14} />;
    case BUTTON_ICON.close: return <SVGClose size={14} />;
    case BUTTON_ICON.retest: return <SVGRetest size={14} />;
    case BUTTON_ICON.scroll_top: return <SVGArrow size={14} rotate={180} />;
    case BUTTON_ICON.scroll_bottom: return <SVGArrow size={14} />;
    case BUTTON_ICON.export: return <SVGExport size={14} />;
    case BUTTON_ICON.import: return <SVGImport size={14} />;
    case BUTTON_ICON.padlock: return <SVGPadlockLocked size={14} />;
    case BUTTON_ICON.pencil: return <SVGPencil size={14} />;
    case BUTTON_ICON.plus: return <SVGPlus size={14} />;
    case BUTTON_ICON.tick: return <SVGTick size={14} />;
    case BUTTON_ICON.delete_circle: return <SVGDeleteCircleSolid size={14} />;
    case BUTTON_ICON.plus_circle: return <SVGPlusCircleSolid size={14} />;
    case BUTTON_ICON.send: return <SVGSend size={14} />;
    case BUTTON_ICON.restore: return <SVGRestore size={14} />;
    case BUTTON_ICON.info: return <SVGInfo size={14} />;
    case BUTTON_ICON.eye: return <SVGEyeVisible size={14} />;
    case BUTTON_ICON.print: return <SVGPrint size={14} />;
    case BUTTON_ICON.external_link: return <SVGExternalLink size={14} />;
  }
  return <></>;
};

const initialState = {
  open: false,
  active: false,
  downloading: false
};

const reducer = (state, { type, active, open }) => {
  const s = () => {
    switch (type) {
      case 'FLIP_MENU': return { open: !state.open };
      case 'MENU_OPEN_CHANGE': return { open };
      case 'ACTIVE_CHANGE': return { active };
      case 'FETCH_BEGIN': return { downloading: true };
      case 'FETCH_FINISH': return { downloading: false };
    }
  };
  return { ...state, ...s() };
};

const FLOW_ICON = {
  [BUTTON_FLOW.FORWARD]: SVGArrow,
  [BUTTON_FLOW.FORWARD_2]: SVGArrowDouble,
  [BUTTON_FLOW.FORWARD_3]: SVGArrowSolid,
  [BUTTON_FLOW.BACKWARD]: SVGArrow,
  [BUTTON_FLOW.BACKWARD_2]: SVGArrowDouble,
  [BUTTON_FLOW.BACKWARD_3]: SVGArrowSolid
};

export const Button = (props) => {
  const {
    options,
    dataAttributes,

    href,
    hrefParams,
    hrefMethod,
    download,
    downloadMime,
    base64,

    newTab,
    stopPropagation,
    disabled: propsDisabled,
    loading: propsLoading,
    autoLoading,

    tooltip,
    icon,
    theme,
    flow,
    priority,

    width,
    style,
    iconAlignment,

    children,

    onClick: propsOnClick
  } = props;

  const [
    {
      open,
      active,
      downloading
    },
    dispatch
  ] = useLoggingReducer(reducer, initialState, undefined, { title: 'Button' });

  const loading = useMemo(
    () => propsLoading || downloading,
    [propsLoading, downloading]
  );

  const disabled = useMemo(
    () => propsDisabled || downloading,
    [propsDisabled, downloading]
  );

  const [errorMessage, setAnimatingErrorMessage] = useAnimatingState(undefined, 3000);
  const onClick = useAPICallback(
    async (api, payload) => {
      Log.breadcrumb('button', (payload ? `payload - ${payload}` : ''));
      if (!autoLoading) return propsOnClick(payload);
      dispatch({ type: 'FETCH_BEGIN' });
      await propsOnClick(payload)
        .catch(e => setAnimatingErrorMessage(e.message || 'Error'));
      if (!api.current) return;
      dispatch({ type: 'FETCH_FINISH' });
    },
    [autoLoading, propsOnClick, dispatch]
  );

  const oauth = useRef(new OAuth());
  const downloadStuff = useCallback(
    async props => {
      let {
        href,
        hrefParams,
        hrefMethod = '',
        download,
        downloadMime,
        base64
      } = props;

      if (!download) return;

      let fileExtension = download.match(/\.[0-9a-z]+$/i)[0];
      let mime = downloadMime || MIME_TYPE_EXTENSIONS[fileExtension];

      if (base64) return oauth.current.downloadBlob(b64toArrayBuffer(base64), download, mime);

      dispatch({ type: 'FETCH_BEGIN' });
      try {
        switch (String(hrefMethod).toUpperCase()) {
          case 'POST': return await oauth.current.postDownload(href, hrefParams, download, mime);
          default: return await oauth.current.getDownload(href, hrefParams, download, mime);
        }
      } catch (e) {
        Log.error(e);
      } finally {
        dispatch({ type: 'FETCH_FINISH' });
      }
    },
    [dispatch]
  );

  const onOptionClick = useCallback(
    option => {
      const {
        value,
        download
      } = option;

      if (disabled) return;
      if (download) downloadStuff(option);
      onClick(value);
      dispatch({ type: 'MENU_OPEN_CHANGE', open: false });
    },
    [onClick, disabled, downloadStuff]
  );

  const handleDownload = useCallback(
    () => {
      if (download) downloadStuff({
        href,
        hrefParams,
        hrefMethod,
        download,
        downloadMime,
        base64
      });
    },
    [href, hrefParams, hrefMethod, download, downloadMime, base64, downloadStuff]
  );

  const onButtonClick = useCallback(
    e => {
      if (stopPropagation) e.stopPropagation();
      if (disabled) return;
      if (options) return dispatch({ type: 'FLIP_MENU' });

      handleDownload();
      onClick();
    },
    [onClick, disabled, options, handleDownload, stopPropagation, dispatch]
  );

  const onBlur = useCallback(
    e => options && eventDidBlur(e) && dispatch({ type: 'MENU_OPEN_CHANGE', open: false }),
    [options, dispatch]
  );

  const onMouseDown = useCallback(() => dispatch({ type: 'ACTIVE_CHANGE', active: true, _no_log: true }), [dispatch]);
  const onMouseLeave = useCallback(() => dispatch({ type: 'ACTIVE_CHANGE', active: false, _no_log: true }), [dispatch]);

  let classes = classNames(
    'styleguide-v1',
    `system-button system-button--${priority}`,
    {
      ['system-button--active']: active,
      [`system-button--${theme}`]: theme,
      [`system-button--icon system-button--icon-${icon}`]: icon,
      ['system-button--disabled']: disabled || errorMessage
    }
  );

  const link = useMemo(
    () => (!download && href) ? href + (hrefParams ? `?${qs.stringify(hrefParams, { arrayFormat: 'brackets' })}` : '') : undefined,
    [download, href, hrefParams]
  );

  const [buttonRef, setButtonRef] = useUpdatingElementRef();

  const FlowIcon = FLOW_ICON[flow];

  return (
    <div
      tabIndex={'-1'}
      onBlur={onBlur}
    >
      <a
        className={classes}
        ref={setButtonRef}
        style={{
          ...style,
          ...width && ({ width })
        }}
        role={'button'}

        disabled={disabled || errorMessage}
        onClick={onButtonClick}

        href={link}
        {...newTab ? {
          rel: 'noreferrer noopener',
          target: '_blank'
        } : {
          rel: undefined,
          target: '_self'
        }}

        onMouseDown={onMouseDown}
        onMouseLeave={onMouseLeave}

        {...dataAttributes}
        data-component={'button'}
        data-is-dropdown={!!options}
        data-open={!!open}
        data-disabled={!!disabled}
      >

        <ToolTipWrapper
          disabled={!tooltip}
          size={TOOLTIP_SIZE.SMALL}
          content={tooltip}
        >
          <span>

            {([BUTTON_FLOW.BACKWARD, BUTTON_FLOW.BACKWARD_2, BUTTON_FLOW.BACKWARD_3].includes(flow)) && (
              <div
                className={'system-button__arrow system-button__arrow--backward'}
                data-component={`button-arrow-wrapper-${flow}`}
              >
                <FlowIcon size={14} rotate={90} data-component={'button-arrow-backward'} />
              </div>
            )}

            {loading && (
              <div
                className={'system-button__icon-wrapper'}
                style={{ marginRight: children && 8 }}
              >
                <SVGRefreshLoading
                  loading
                  size={14}
                />
              </div>
            )}

            {(iconAlignment == 'left' && icon) && (
              <div
                className={'system-button__icon-wrapper'}
                style={{ marginRight: children && 8 }}
              >
                <ButtonImage icon={icon} />
              </div>
            )}

            {errorMessage || children}

            {(iconAlignment == 'right' && icon) && (
              <div
                className={'system-button__icon-wrapper'}
                style={{ marginLeft: children && 8 }}
              >
                <ButtonImage icon={icon} />
              </div>
            )}

            {([BUTTON_FLOW.FORWARD, BUTTON_FLOW.FORWARD_2, BUTTON_FLOW.FORWARD_3].includes(flow)) && (
              <div
                className={'system-button__arrow system-button__arrow--forward'}
                data-component={`button-arrow-wrapper-${flow}`}
              >
                <FlowIcon size={14} rotate={270} data-component={'button-arrow-forward'} />
              </div>
            )}

            {options && (
              <div className={'system-button__arrow system-button__option-indicator'}>
                <SVGArrow
                  size={14}
                  rotate={open ? 180 : 0}
                />
              </div>
            )}
          </span>
        </ToolTipWrapper>
        {options && (
          <DropDownModal
            className={'system-button__option-menu'}
            pinRef={buttonRef}
            matchWidth={theme != BUTTON_THEME.text}
            isOpen={open}
          >
            <div
              className={'system-button__option-list'}
              data-component={'button-option-list'}
            >
              {options.map(
                (option, i) => (
                  <Option
                    key={option.key || option.value}
                    stopPropagation={stopPropagation}
                    option={option}
                    newTab={newTab}
                    onClick={onOptionClick}
                  />
                )
              )}
            </div>
          </DropDownModal>
        )}
      </a>
    </div>
  );
};

Button.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.any,
    value: PropTypes.any,

    href: PropTypes.string,
    hrefParams: PropTypes.object,
    hrefMethod: PropTypes.string,
    download: PropTypes.string,
    downloadMime: PropTypes.string,
    base64: PropTypes.string
  })),

  href: PropTypes.string,
  hrefParams: PropTypes.object,
  hrefMethod: PropTypes.string,
  download: PropTypes.string,
  downloadMime: PropTypes.string,
  base64: PropTypes.string, // Requires a string encoded with btoa(unescape(encodeURIComponent(source)))

  newTab: PropTypes.bool,
  stopPropagation: PropTypes.bool,

  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  autoLoading: PropTypes.bool,

  iconAlignment: PropTypes.string,

  tooltip: PropTypes.any,
  flow: PropTypes.string,
  priority: PropTypes.string,
  theme: PropTypes.string,
  icon: PropTypes.any,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  onClick: PropTypes.func
};

Button.defaultProps = {
  priority: BUTTON_PRIORITY.quaternary,

  disabled: false,
  loading: false,

  iconAlignment: 'right',
  newTab: false,
  stopPropagation: true,

  dataAttributes: {},
  onClick: () => { },
};

export default Button;
