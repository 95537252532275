import { Button, BUTTON_THEME } from './v2';
import { SVGPlusCircle, SVGExport, SVGExternalLink } from '_Icons';
import Log from '../../lib/Log';
import { SPECIAL_DEMOGRAPHICS } from '_Lib/Enum';

const SIZES = [48, 40, 32, 24, 16];

const DROPDOWN_OPTIONS = [
  {
    value: 1,
    label: 'Option 1'
  },
  {
    value: 2,
    label: 'Option 2',
    disabled: true
  },
  {
    value: 3,
    label: 'Option 3'
  },
  {
    value: 4,
    label: 'Option 4'
  },
  {
    value: 5,
    label: 'Export',
    href: `api/v1/reports/surveys/3437/demographics/${SPECIAL_DEMOGRAPHICS.LEVEL.ID}/options/compare.xlsx`,
    hrefParams: {
      question_type: 'ayt-0-6',
      sort: '-difference',
      options: [
        SPECIAL_DEMOGRAPHICS.LEVEL.EXECUTIVE,
        SPECIAL_DEMOGRAPHICS.LEVEL.NON_EXECUTIVE
      ],
      current_selection: {}
    },
    hrefMethod: 'GET',
    download: 'Alignment Report - Large Survey - Baseline.xlsx',
    trailingIcon: <SVGExport size={16} />
  }
];

const Variation = ({ Button }) => (
  <table className={'table'} style={{ marginBottom: 64 }} >
    <tbody>
      <tr>
        <td>
          <Button
            href={`api/v1/reports/surveys/3437/demographics/${SPECIAL_DEMOGRAPHICS.LEVEL.ID}/options/compare.xlsx`}
            hrefParams={{
              question_type: 'ayt-0-6',
              sort: '-difference',
              options: [
                SPECIAL_DEMOGRAPHICS.LEVEL.EXECUTIVE,
                SPECIAL_DEMOGRAPHICS.LEVEL.NON_EXECUTIVE
              ],
              current_selection: {}
            }}
            hrefMethod={'GET'}
            download={'Alignment Report - Large Survey - Baseline.xlsx'}
            trailingIcon={<SVGExport size={16} />}
          >
            Export
          </Button>
        </td>
        <td>
          <Button
            href={'/library'}
            trailingIcon={<SVGExternalLink size={16} />}
          >
            Go to Library
          </Button>
        </td>
      </tr>
      <tr>
        {SIZES.map(
          (size, key) => (
            <td key={key} >
              <Button
                options={DROPDOWN_OPTIONS}
                closeOnSelect={false}
                onClick={e => Log.log(e || 'nothing')}
                size={size}
              >
                Dropdown
              </Button>
            </td>
          )
        )}
      </tr>
      <tr>
        {SIZES.map(
          (size, key) => (
            <td key={key} >
              <Button size={size} >
                {`Button size ${size}`}
              </Button>
            </td>
          )
        )}
      </tr>
      <tr>
        {SIZES.map(
          (size, key) => (
            <td key={key} >
              <Button size={size} disabled >
                Disabled
              </Button>
            </td>
          )
        )}
      </tr>
      <tr>
        {SIZES.map(
          (size, key) => (
            <td key={key} >
              <Button size={size} loading >
                Loading
              </Button>
            </td>
          )
        )}
      </tr>
      <tr>
        {SIZES.map(
          (size, key) => (
            <td key={key} >
              <Button
                leadingIcon={<SVGPlusCircle size={16} />}
                size={size}
              >
                Create thing
              </Button>
            </td>
          )
        )}
      </tr>
      <tr>
        {SIZES.map(
          (size, key) => (
            <td key={key} >
              <Button
                trailingIcon={<SVGExport size={16} />}
                size={size}
              >
                Export thing
              </Button>
            </td>
          )
        )}
      </tr>
    </tbody>
  </table>
);

const VARIATIONS = [
  {
    theme: BUTTON_THEME.PRIMARY,
  },
  {
    theme: BUTTON_THEME.PRIMARY,
    rounded: true
  },
  {
    theme: BUTTON_THEME.SECONDARY,
  },
  {
    theme: BUTTON_THEME.SECONDARY,
    rounded: true
  },
  {
    theme: BUTTON_THEME.TERTIARY
  },
  {
    theme: BUTTON_THEME.TEXT_PRIMARY
  },
  {
    theme: BUTTON_THEME.TEXT_GREY
  },
  {
    theme: BUTTON_THEME.TEXT_BLACK
  }
];

const ButtonVariationsPortal = (props) => (
  VARIATIONS.map(
    (injectedProps, i) => (
      <Variation
        key={i}
        Button={
          props => (
            <Button
              {...injectedProps}
              {...props}
            />
          )
        }
      />
    )
  )
);

export default ButtonVariationsPortal;
