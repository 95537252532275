import { useCallback } from 'react';

const Option = (props) => {
  const {
    option,
    stopPropagation,
    onClick: propsOnClick
  } = props;

  const {
    value,
    label
  } = option;

  const onClick = useCallback(
    e => {
      if (stopPropagation) e.stopPropagation();
      propsOnClick(option);
    },
    [propsOnClick, option, stopPropagation]
  );

  return (
    <div
      className={'system-button__option'} onClick={onClick}

      data-component={'button_option'}
      data-value={value}
      data-label={label}
    >
      <div className={'system-button__option-inner'} >
        {label}
      </div>
    </div>
  );
};

export default Option;
